<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{path:'/illnessManage'}">疾病管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path:'/illnessManage/illAddCase',query:{ill_id:ill_id,name:name}}">好转病例</el-breadcrumb-item>
            <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form label-width="120px" ref="form" class="casedetail" :model="form">
            <el-form-item label="姓名">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="年龄">
                <el-input v-model="form.age"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
                <el-select v-model="form.sex" placeholder="请选择性别">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所患疾病">
                <el-input v-model="form.ill"></el-input>
            </el-form-item>
            <el-form-item label="排序">
                <el-input style="float: left;" type="number" v-model="form.sort" autocomplete="off"
                    placeholder="请输入数字">
                </el-input>
                <span style="float: left;color: #909399;width: 100%;text-align: left;">数字越大排名越靠前</span>
            </el-form-item>
            <el-form-item label="封面图片">
                <upload :src.sync="form.video_pic"></upload>
            </el-form-item>
            <el-form-item label="视频地址链接">
                <el-input v-model="form.video"></el-input>
            </el-form-item>
            <el-form-item label="头像">
                <upload :src.sync="form.headimg"></upload>
            </el-form-item>
            <el-form-item label="病情概述">
                <el-input v-model="form.summary" type="textarea" maxlength="500" rows="5" placeholder="最长输入500字"></el-input>
            </el-form-item>
            <el-form-item label="治疗前">
                <el-input v-model="form.ill_before" type="textarea" rows="5" ></el-input>
            </el-form-item>
            <el-form-item label="治疗后">
                <el-input v-model="form.ill_after" type="textarea" rows="5" ></el-input>
            </el-form-item>
            <el-form-item label="治疗简介">
                <FullEditor id="illAddCaseDetail" rows="10" upload="/"></FullEditor>
            </el-form-item>
            <div>
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="sub">确 定</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import upload from '../components/upload'
    import FullEditor from '../components/FullEditor.vue'
    export default {
        components: {
            upload, FullEditor
        },
        data() {
            return {
                id: "",
                ill_id: '',
                type: '',
                title: '添加好转病例',
                options: [
                    {
                        value: '男',
                        label: '男'
                    }, {
                        value: '女',
                        lebel: '女'
                    }
                ],
                desc: '',
                form: {
                    name: '',
                    age: '',
                    sex: '',
                    video: '',
                    headimg: '',
                    desc: '',
                    doc_id: '',
                    ill_lib_id:'',
                    video_pic:'',
                    summary:'',
                    sort:0,
                    ill:'',
                    ill_before:'',
                    ill_after:''
                },
                illoptions:[],
                name:''
            }
        },
        mounted() {
            this.form.ill_lib_id = this.$route.query.ill_id;
            this.ill_id = this.$route.query.ill_id;
            this.name = this.$route.query.name;
            this.type = this.$route.query.type;
            if (this.type != 'new') {
                this.title = '编辑好转病例';
                this.id = this.$route.query.id;
                this.axios.get("/gu/get_gu_showcust_by_id?id=" + this.id)
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            // console.log(rs);
                            this.form = rs.data.result;
                            // this.desc = this.htmlDecode(rs.data.result.desc);
                            let txt = this.htmlDecode(rs.data.result.desc);
                            this.$nextTick(()=>setTimeout(() => {tinymce.activeEditor.setContent(txt)}, 300));
                        }else{
                            console.log(rs);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            this.getill();
        },
        methods: {
            getill(){
                this.axios.get("/gu/get_gu_ill_lib")
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.illoptions = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            htmlEncode: function (html) {
                //1.首先动态创建一个容器标签元素，如DIV
                var temp = document.createElement("div");
                //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
                temp.textContent != undefined
                    ? (temp.textContent = html)
                    : (temp.innerText = html);
                //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
                var output = temp.innerHTML;
                temp = null;
                return output;
            },
            /*2.用浏览器内部转换器实现html解码（反转义）*/
            htmlDecode: function (text) {
                //1.首先动态创建一个容器标签元素，如DIV
                var temp = document.createElement("div");
                //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
                temp.innerHTML = text;
                //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
                var output = temp.innerText || temp.textContent;
                temp = null;
                return output;
            },
            cancel() {
                this.$router.push({ path: '/illnessManage/illAddCase', query: { ill_id: this.ill_id ,name:this.name} });
            },
            sub() {
                let url = '';
                let str = '';
                if (this.type == 'new') {
                    url = "/gu/insert_gu_showcust";
                    str = '添加成功'
                } else {
                    url = "/gu/update_gu_showcust";
                    str = "修改成功";
                    this.form.id = this.id;
                }
                let data = tinyMCE.activeEditor.getContent();
                this.form.desc = this.htmlEncode(data);
                console.log(this.form);
                this.axios.post(url, this.qs.stringify(this.form))
                    .then((rs) => {
                        // console.log(rs)
                        if(rs.data.code == 0){
                            this.form = {};
                        this.$message({
                            message: str,
                            type: 'success'
                        });
                        this.$router.push({ path: '/illnessManage/illAddCase', query: { ill_id: this.ill_id ,name:this.name} });
                        }else{
                            this.$message(rs.data.msg);
                        }
                        
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>
<style>
    .el-form {
        width: 80%;
        margin-top: 50px;
    }

    .el-select {
        width: 40%;
        float: left;
    }
    .casedetail .el-input {
    width: 80%;
    float: left;
}
</style>